<template>
    <div class="account">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: 100%; overflow-y: auto;">
            <Top name="我的账户" setting email class="account_top"></Top>
            <van-loading v-if="loading" color="#1989fa" />
            <div v-else class="account_w">
                <div class="account_t">
                    <van-row type="flex" justify="center">
                        <van-col span="21">
                            <div class="photo_w">
                                <div>
                                    <div class="datamodify_w">
                                        <span v-if="isPhoto" @click="$router.push('/login')">立即登录</span>
                                        <div v-else class="alter_img">
                                            <img :src="photoSrc" alt="">
                                            <van-uploader 
                                            :max-size="10000 * 1024"
                                            :after-read="afterRead" 
                                            @oversize="onOversize"
                                            ref="upload"
                                            >
                                                <van-button icon="plus" type="primary">上传文件</van-button>
                                            </van-uploader>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="!isPhoto" style="font-size: .8rem;">15167178017</div> -->
                            <p class="user_name">{{isPhoto ? tiptext : username}}</p>
                        </van-col>
                        <!-- <div class="change">
                            <span>切换账号</span>
                            <van-icon name="exchange" size="1rem" />
                        </div> -->
                        <van-col span="16" class="safe_set">
                            <span>安全设置：</span>
                            <router-link to="/safesetting">
                                <van-button plain type="info" size="mini">{{isSet}}</van-button>
                            </router-link>
                        </van-col>
                        <van-col span="21" class="balance_w">
                            <div class="balance_l">
                                <p>账户余额：</p>
                                <div>
                                    <span>￥{{totalBalance}}</span>
                                    <van-popover
                                        v-model="showPopover"
                                        trigger="click"
                                        theme="dark"
                                        placement="right-start"
                                        get-container="#question_icon"
                                        >
                                        <div class="balance_pop">
                                            <p>
                                                <span>可提现余额：</span><span>{{accountBalance}}元</span>
                                            </p>
                                            <p>
                                                <span>考察期余额：</span><span>{{frozenBalance}}元</span>
                                            </p>
                                            <p>提示：考察期余额24小时后</p>
                                            <p>方可提现、购买角色！</p>
                                        </div>
                                        <template #reference>
                                            <van-icon name="question-o" size="1.4rem" id="question_icon" />
                                        </template>
                                    </van-popover>
                                </div>
                            </div>
                            <div class="balance_r" style="position: relative;left: 2%;">
                                <router-link :to="isSet == '未设置' ? '' : '/withdraw'">
                                    <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="withdrawClick">提现</van-button>
                                </router-link>
                            </div>
                        </van-col>
                    </van-row>
                </div>
                <div class="account_b">
                    <van-row type="flex" justify="space-around">
                        <van-col span="4"> 
                            <router-link to="/trade">
                                <img src="../assets/images/account_icon1.png" alt="">
                                <span>我的订单</span>
                            </router-link>
                        </van-col>
                        <van-col span="4"> 
                            <router-link to="/wallet">
                                <img src="../assets/images/account_icon2.png" alt="">
                                <span>钱包收支</span>
                            </router-link>
                        </van-col>
                        <van-col span="4"> 
                            <router-link to="/deallog">
                                <img src="../assets/images/account_icon3.png" alt="">
                                <span>成交动态</span>
                            </router-link>
                        </van-col>
                    </van-row>
                    <van-row type="flex" justify="space-around">
                        <van-col span="4"> 
                            <router-link to="/footprint">
                                <img src="../assets/images/account_icon4.png" alt="">
                                <span>我的足迹</span>
                            </router-link>
                        </van-col>
                        <van-col span="4"> 
                            <router-link to="/service">
                                <img src="../assets/images/account_icon7.png" alt="">
                                <span>联系客服</span>
                            </router-link>
                        </van-col>
                        <van-col span="4">
                            <router-link to="/rule">
                                <img src="../assets/images/account_icon8.png" alt="">
                                <span>交易规则</span>
                            </router-link>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </van-pull-refresh>
    </div>
</template>
<script>
import base from '@/api/base'
import Top from '@/components/top'

export default {
    name: 'Account',
    components: {
        Top,
    },
    data() {
        return {
            isLoading: false,
            loading: true,
            showPopover: false,
            isPhoto: false,
            photoSrc: require('../assets/images/profilePhoto.png'),
            username: '',
            tiptext: '您还没有登录哦！',
            accountBalance:0,
            frozenBalance:0,
            totalBalance: 0,
            isSet:'未设置',
            isShow:true
        }
    },
    created(){
        if(sessionStorage.getItem('type')=='24'){
            this.isShow = false
        }
        // function geTel(tel){
            var reg = /^(\d{3})\d{4}(\d{4})$/;  
            // tel.replace(reg, "$1****$2");
        // }
        if(localStorage.getItem('username')){
            this.username = localStorage.getItem('username');
            this.username = this.username.replace(reg, "$1****$2")
        }
        
        // let that = this;
        // this.$api.account.getBalance({username:username}).then((res)=>{
        //     this.loading = false
        //     // console.log(res); 
        //     this.totalBalance = Number(res.data.accountBalance + res.data.frozenBalance).toFixed(2);
        //     this.accountBalance = res.data.accountBalance;
        //     this.frozenBalance = res.data.frozenBalance;
        //     res.data.is_set_up==0?this.isSet='未设置':this.isSet='已设置';
        // })
        // this.$api.account.BasicInformation().then(res=>{
        //     this.loading = false
        //     if (res.code == 200) {
        //         this.username = res.data.nickname;
        //         if (res.data.headPortrait) {
        //             this.photoSrc = base.bd + '/' + res.data.headPortrait;
        //         }else{
        //             this.photoSrc = require('../assets/images/profilePhoto.png');
        //         }
        //     }
        // })
        // if(!localStorage.getItem('token')){
        //     this.isPhoto = true;
        //     return false;
        // }else{
        //     this.isPhoto = false;
        // }

        //获取头像等基础信息
        this.getBasicInfo();
        //获取我的账户金额等信息
        this.getBalance();
    },
    methods: {
        //下拉刷新
        onRefresh(){
            //console.log(222)
            //获取头像等基础信息
            this.getBasicInfo();
            //获取我的账户金额等信息
            this.getBalance();
        },
        //获取头像等基础信息
        getBasicInfo() {
            this.$api.account.BasicInformation().then(res=>{
                this.loading = false;
                this.isLoading = false;
                if (res.code == 200) {
                    // this.username = res.data.nickname;
                    if (res.data.headPortrait) {
                        this.photoSrc = base.bd + '/' + res.data.headPortrait;
                    }else{
                        this.photoSrc = require('../assets/images/profilePhoto.png');
                    }
                }
            });
            if(!localStorage.getItem('token')){
                this.isPhoto = true;
                return false;
            }else{
                this.isPhoto = false;
            }
        },
        //获取我的账户金额等信息
        getBalance() {
            let username = localStorage.getItem('username');
            this.$api.account.getBalance({username:username}).then((res)=>{
                this.loading = false;
                this.isLoading = false;
                // console.log(res); 
                this.totalBalance = Number(res.data.accountBalance + res.data.frozenBalance).toFixed(2);
                this.accountBalance = res.data.accountBalance;
                this.frozenBalance = res.data.frozenBalance;
                res.data.is_set_up==0?this.isSet='未设置':this.isSet='已设置';
            })
        },
        //上传头像限制
        onOversize(file) {
            this.$toast('文件大小不能超过 10Mb');
        },
        //如果提现不可跳转则给提示
        withdrawClick() {
            if (this.isSet == '未设置') {
                this.$toast({
                    duration: 1500,
                    message: '为保证您的账户安全，请先完成安全设置哦！'
                });
            }
        },
        afterRead(file) {
            //console.log(file.file);
            this.uploadSrc = file.content;
            this.imgFile = file;

            let formData=new FormData();//通过formdata上传
            if (this.imgFile.file) {
                formData.append('image',this.imgFile.file);
            }else{
                formData.append('image','');
            }
            formData.append('nickname',this.username);
            this.$api.account.updateBasicInformation(formData).then(res=>{
                this.photoSrc = file.content;
                if(res.code==200){
                    this.$toast({
                        message: '修改成功',
                        duration: 1500
                    });
                }
            })
        },
    }
}
</script>
<style scoped>
/* 上传文件样式 */
.datamodify_w .alter_img{
    position: relative;
    display: inline-block;
    width: 100%;
}
.datamodify_w .alter_img img{
    /* width: 5.0625rem; */
    height: 4.921rem;
    border-radius: 50%;
    /* border: .125rem solid #ccc; */
}
.datamodify_w .alter_img .van-uploader{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
}
</style>
<style>
.account{
    height: 100%;
    min-height: 100%;
    padding-bottom: 50px;
    background-color: #ececec;
}
.account_top .public_header{
    background: url('../assets/images/header_bg.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.account_w{
    background-color: #fff;
}
.account_t{
    color: #fff;
    background: url('../assets/images/account_bg.png') no-repeat top center;
    background-size: 100%;
}
.account_t .van-row{
    position: relative;
}
.account_t .change{
    position: absolute;
    top: 1.4375rem;
    right: 3%;
    font-size: .75rem;
    display: flex;
}
.account_t .photo_w{
    width: 7rem;
    height: 7rem;
    background-color: rgba(248, 94, 87, .5);
    border-radius: 50%;
    margin: .5% auto;
    position: relative;
}
.account_t .photo_w>div{
    width: 6rem;
    height: 6rem;
    background-color: rgba(255, 110, 103, .5);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.account_t .photo_w>div>div{
    width: 5.2rem;
    height: 5.2rem;
    line-height: 5.2rem;
    background-image: linear-gradient(to right, #f9442f, #fd6244);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid #ffd2cf;
    font-size: 1rem;
}
.account_t .photo_w img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.account_t .photo_w>p{
    position: absolute;
    top: 5px;
    right: 5px;
}
.account_t .user_name{
    font-size: .8rem;
    min-height: 1rem;
}
.safe_set{
    border-radius: 50px;
    background-color: #ea666a;
    font-size: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1% 0;
    letter-spacing: 1px;
    margin: 1% 0;
}
.safe_set a{
    width: 3.7rem;
}
.safe_set .van-button{
    width: 100%;
    background: #ea666a;
    color: #fff;
    border-color: #fff;
    padding: 0 4%;
    border-radius: 4px;
    margin-left: 4%;
}
.balance_w{
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 #909090;
    display: flex;
    padding: 4% 3%;
    align-items: center;
}
.balance_w>div{
    flex: 1;
}
.balance_w>div:first-child{
    color: #313131;
    border-right: 1px solid #cecece;
    text-align: left;
    font-size: .8rem;
}
.balance_l p{
    color: #707070;
}
.balance_l div{
    font-size: 1.3125rem;
    line-height: 1.2em;
}
.balance_l div span{
    vertical-align: middle;
    margin-right: 2%;
}
.balance_l .van-icon{
    vertical-align: middle;
}
.balance_r button{
    padding: 0 2rem;
    font-size: .9375rem;
}
.account_b{
    padding: 1.5rem 0;
}
.account_b .van-row{
    padding: .3rem 0;
}
.account_b a{
    color: #313131;
    font-size: .9rem;
    display: block;
}
.account_b a>span{
    white-space: nowrap;
}
.account_b img{
    width: 100%;
}
/* 气泡弹窗样式 */
.balance_l .balance_pop{
    padding: 1rem;
    font-size: .8rem;
}
.balance_pop p{
    margin-bottom: .3rem;
    color: #fff;
    white-space:nowrap;
    display: flex;
    justify-content: space-between;
}
.balance_l .van-popover__arrow{
    display: none;
}
</style>